<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <ClearCacheMeta v-if="productsError" />
  <section :class="`${block.backgroundColor}`">
    <div class="mx-auto max-w-6xl pb-9 pt-6 md:px-6 md:pb-14 md:pt-12">
      <div class="mb-6">
        <h2
          class="px-3 pb-2 text-center text-2xl text-dynamic-primary md:mt-[-5px]"
        >
          {{ block.heading }}
        </h2>
        <div
          v-if="block.textArea"
          class="text-center text-lg text-dynamic-secondary [&_h1]:text-3xl [&_h1]:leading-normal md:[&_h1]:md:text-5xl [&_p]:pt-3 [&_p]:text-lg"
          v-html="block.textArea"
        ></div>
      </div>
      <article>
        <ProductsSwiper v-if="products.length > 0" :products="products">
          <template #product="{ product }">
            <LazyProductCard
              :id="product?.node?.entityId"
              :key="product?.node?.entityId"
              class="size-full border-y border-black/6 odd:border-r md:border-none"
              :slug="product?.node?.slug"
              :title="product?.node?.name"
              :campaign="product?.node?.campaign"
              :regular-price="
                product?.node?.prices && product?.node?.prices.basePrice
                  ? product?.node?.prices.basePrice.value
                  : ''
              "
              :special-price="
                product?.node?.prices && product?.node?.prices.salePrice
                  ? product?.node?.prices.salePrice.value
                  : ''
              "
              :is-cheapest-variant-price="
                product?.node?.prices &&
                product?.node?.prices.isCheapestVariantPrice
              "
              :image="
                product?.node?.defaultImage
                  ? product?.node?.defaultImage.url
                  : ''
              "
              :brand="product?.node?.brand"
              :options="product?.node?.options"
              :variants="product?.node?.variants"
              :category-ids="product?.node?.categoryIds"
              :is-available="product?.node?.isAvailable"
              :show-animal-types="false"
              :tracking-data="{
                product: {
                  entityId: product?.node?.entityId,
                  name: product?.node?.name,
                  prices: {
                    basePrice: product?.node?.prices?.basePrice || null,
                    salePrice: product?.node?.prices?.salePrice || null,
                  },
                },
                extraFields: { brand: product?.node?.brand?.name },
                list: `In Pagebuilder ${route?.params?.slug || 'Home'}`,
              }"
            />
          </template>
        </ProductsSwiper>

        <template v-for="childBlock in block.children" :key="childBlock.id">
          <div
            v-if="childBlock.typeHandle === 'BlockButton'"
            class="mt-8 flex justify-center"
          >
            <PageBuilder :blocks="[childBlock]" :globals="globals" />
          </div>
        </template>
      </article>
    </div>
  </section>
</template>

<script setup lang="ts">
import { gql } from '@apollo/client/core';
import { mapProductsData } from '@/helpers/product-data-mapper';
import { productFragment } from '@/graphql/fragments';

const route = useRoute();

const runtimeConfig = useRuntimeConfig();
const { isDevStore } = runtimeConfig.public;

const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const devProductId = 4995;

let productsError: boolean = false;

const ids = computed(() => {
  if (isDevStore) {
    return [devProductId]; // Dev product
  }
  return props?.block?.children
    .map((item: any) => item.text)
    .filter((item: any) => item)
    .map((item: any) => parseInt(item, 10));
});

const result = await useLazyAsyncQuery({
  query: gql`
    query Products($ids: [ID!]) {
      products(ids: $ids) {
        edges {
          node {
            ...ProductFields
            brand {
              entityId
              name
              path
              slug
            }
          }
        }
      }
    }
    ${productFragment}
  `,
  variables: {
    ids: ids.value,
  },
});

if (result?.error?.value) {
  productsError = true;
}

const realProducts = computed(() =>
  mapProductsData(result?.data?.value?.data?.products)
);

const devProducts = computed(() => Array(8).fill(realProducts.value[0]));

const products = isDevStore ? devProducts : realProducts;
</script>
